<template>
  <v-col
    :class="borderClasses"
    class="cw-business-loan-terms-checkbox cw-border-container"
    cols="12"
  >
    <div
      v-if="title"
      class="py-2"
    >
      <h3
        class="
          text-body-2
          font-weight-bold
        "
      >
        {{ title }}
      </h3>
    </div>
    <div>
      <cw-checkbox
        v-model="form[$attrs.id]"
        v-bind="$attrs"
        :rules="rules"
        class="mt-0"
        hide-details
      >
        <template #label>
          <slot name="label"/>
        </template>
      </cw-checkbox>
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'CwBusinessLoanTermsCheckbox',

  components: {
    'cw-checkbox': () => import('@shared/components/inputs/CwCheckbox'),
  },

  inheritAttrs: false,

  props: {
    borderColor: {
      type: String,
      default: '',
    },
    center: {
      type: String,
      default: '',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    rules: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  computed: {
    borderClasses() {
      if (!this.borderColor) return {};

      return {
        [`cw-border-container--${this.borderColor}`]: this.borderColor !== '',
        [`cw-border-container--${this.center}-center`]: this.center !== '',
      };
    },
  },

  created() {
    const { id } = this.$attrs;
    if (!id) {
      console.error('[BusinessLoanTermsCheckbox] - An id has to be defined. Please use form property name.');
    } else if (!Object.keys(this.form).includes(id)) {
      console.error(`[BusinessLoanTermsCheckbox] - Form has no property ${id}`);
    }
  },
};
</script>
